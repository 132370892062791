import html2canvas from "html2canvas";
import { jsPDF as JsPDF } from "jspdf";
import getDpi from "@/helpers/get-dpi";

export default function downloadAsPdf(
  element: HTMLElement,
  filename: string,
): void {
  void html2canvas(element).then((canvas) => {
    // Create a new jsPDF instance
    const pdf = new JsPDF("p", "mm", "a4", false);

    // Calculate the number of pages required based on the canvas height and PDF settings
    const totalPages = Math.ceil(
      PXToMM(canvas.height) / pdf.internal.pageSize.getHeight(),
    );

    // Loop through each page and add it to the PDF
    for (let i = 0; i < totalPages; i += 1) {
      // Calculate the y-coordinate for the current page's slice of the canvas
      const startY = MMToPX(pdf.internal.pageSize.getHeight() * i);

      // Create a new canvas element to hold the current page's slice
      const pageCanvas = document.createElement("canvas");
      const pageContext = pageCanvas.getContext("2d");

      // Set the canvas dimensions to match the PDF page size
      pageCanvas.width = canvas.width;
      pageCanvas.height = MMToPX(pdf.internal.pageSize.getHeight());

      if (pageContext !== null) {
        // Slice the original canvas and copy it to the new canvas element
        pageContext.drawImage(
          canvas,
          0,
          startY,
          canvas.width,
          MMToPX(pdf.internal.pageSize.getHeight()),
          0,
          0,
          canvas.width,
          MMToPX(pdf.internal.pageSize.getHeight()),
        );
      }

      // Convert the new canvas to a data URL
      const pageData = pageCanvas.toDataURL("image/png", 1.0);

      // Add the current page to the PDF document
      pdf.addImage(
        pageData,
        "PNG",
        0,
        0,
        PXToMM(canvas.width),
        pdf.internal.pageSize.getHeight(),
      );

      // Add a new page if there are more pages remaining
      if (i < totalPages - 1) {
        pdf.addPage();
      }
    }
    // Save the PDF document
    pdf.save(`${filename}.pdf`);
  });
}

// mm = (px * 25.4) / dpi
// px = (mm * dpi) / 25.4

const PXToMM = (px: number): number => (px * 25.4) / getDpi();

const MMToPX = (mm: number): number => (mm * getDpi()) / 25.4;
