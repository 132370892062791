import { useEffect } from "react";
import { useSwiper } from "swiper/react";

export default function SlideTo(props: {
  slideIndex: { val: number };
}): React.ReactElement | null {
  const { slideIndex } = props;

  const swiper = useSwiper();

  useEffect(() => {
    swiper.slideTo(slideIndex.val, 100);
  }, [swiper, slideIndex]);

  return null;
}
