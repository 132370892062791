/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import getOrFetch from "@/helpers/get-or-fetch";
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";
import { requestQuery } from "./queries";
import { regionsQuery } from "../acts-list";

const Container = lazy(() => import("./views/container"));
const Requests = lazy(() => import("./views/requests"));
const Request = lazy(() => import("./views/request"));
const PeopleIcon = lazy(() => import("./components/people-icon"));

const registrationRequestsRoutes = canSee(
  {
    id: "registration-requests",
    title: "registration-requests",
    path: "registration-requests",
    Icon: PeopleIcon,
    element: <Container of={<Requests />} />,
    loader: async () => {
      const regions = await getOrFetch(regionsQuery);
      return regions;
    },
    children: [
      {
        id: "registration-request",
        title: "registration-request",
        path: ":requestId",
        element: <Request />,
        loader: async ({ params }) => {
          const resources = await Promise.all([
            getOrFetch(() => requestQuery(params.requestId as string)),
            getOrFetch(regionsQuery),
          ]);

          return resources;
        },
      },
    ],
  },
  getUserClass().role,
);

export default registrationRequestsRoutes;
