/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import { sift } from "radash";
import getOrFetch from "@/helpers/get-or-fetch";
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";
import { registrationRequestsRoutes } from "@/features/registration-requests";
import { groupsQuery, inspectorQuery } from "./queries";
import { regionsQuery } from "../acts-list";

const Container = lazy(() => import("./views/container"));
const Inspectors = lazy(() => import("./views/inspectors"));
const Inspector = lazy(() => import("./views/inspector"));
const PeopleIcon = lazy(() => import("./components/people-icon"));

const inspectorsRoutes = canSee(
  {
    id: "inspectors",
    title: "inspectors",
    path: "inspectors",
    Icon: PeopleIcon,
    children: sift([
      {
        id: "existing-inspectors",
        title: "existing-inspectors",
        path: "existing-inspectors",
        element: <Container of={<Inspectors />} />,
        loader: async () => {
          const regions = await getOrFetch(regionsQuery);
          return regions;
        },
        children: [
          {
            id: "create-inspector",
            title: "create-new-inspector",
            path: "create-inspector",
            element: <Inspector />,
            loader: async () => {
              const regions = await getOrFetch(regionsQuery);
              const groups = await getOrFetch(groupsQuery);
              return [regions, groups];
            },
          },
          {
            id: "update-inspector",
            title: "update-inspector-info",
            path: ":inspectorId",
            element: <Inspector />,
            loader: async ({ params }) => {
              const resources = await Promise.all([
                getOrFetch(() => inspectorQuery(params.inspectorId as string)),
                getOrFetch(regionsQuery),
                getOrFetch(groupsQuery),
              ]);

              return resources;
            },
          },
        ],
      },
      registrationRequestsRoutes,
    ]),
  },
  getUserClass().role,
);

export default inspectorsRoutes;
