/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import getOrFetch from "@/helpers/get-or-fetch";
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";
import { statsQuery } from "./queries";

const ChartIcon = lazy(() => import("./components/chart-icon"));
const Stats = lazy(() => import("./views"));

const statsRoutes = canSee(
  {
    id: "statistics",
    title: "statistics",
    path: "statistics",
    element: <Stats />,
    Icon: ChartIcon,
    children: [],
    loader: async () => {
      const res = await getOrFetch(statsQuery);
      return res;
    },
  },
  getUserClass().role,
);

export default statsRoutes;
