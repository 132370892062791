import request from "@/utils/axios";
import type { BaseParams, ListResponse } from "@/types";
import type { Offense, OffenseStatus, OffensesParams } from "../types";

export async function getOffenses(
  params: OffensesParams = {},
): Promise<ListResponse<Offense[]>> {
  const res: ListResponse<Offense[]> = await request({
    url: "/ek-data/",
    method: "get",
    params,
  });
  return res;
}

export async function getOffense(id: number): Promise<Offense> {
  const res: Offense = await request({
    url: `/ek-data/${id}/`,
    method: "get",
  });
  return res;
}

export async function getOffenseStatuses(
  params: BaseParams = {},
): Promise<OffenseStatus[]> {
  const res: OffenseStatus[] = await request({
    url: "/ek-status/",
    method: "get",
    params,
  });
  return res;
}

export async function updateOffenseStatus(
  id: number,
  data: { status_id: number; is_juridic: boolean | null },
): Promise<void> {
  await request({
    url: `/ek-status-update/${id}/`,
    method: "put",
    data,
  });
}

export async function acceptJuridic(id: number, data: { inspector: number }) {
  await request({
    url: `/violations/accept-juridic/${id}/`,
    method: "put",
    data,
  });
}

export async function acceptPerson(id: number) {
  await request({
    url: `/violations/accept-person/${id}/`,
    method: "put",
  });
}

export async function rejectOffense(
  id: number,
  data: { reason?: number; description?: string },
) {
  await request({
    url: `/violations/reject/${id}/`,
    method: "put",
    data,
  });
}
