import type { CacheQuery } from "@/types";
import { getGroups, getInspector } from "../api";

export function inspectorQuery(
  inspectorId: string,
): CacheQuery<Awaited<ReturnType<typeof getInspector>>> {
  return {
    queryKey: ["inspector", inspectorId],
    queryFn: async () => {
      const res = await getInspector(inspectorId);
      return res;
    },
  };
}

export function groupsQuery(): CacheQuery<
  Awaited<ReturnType<typeof getGroups>>
> {
  return {
    queryKey: ["groups"],
    queryFn: async () => {
      const res = await getGroups();
      return res;
    },
  };
}
