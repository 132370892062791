import { useState, useMemo } from "react";
import { authContext } from "@/contexts/auth-context";
import type { User, UserWithAuth } from "@/types";

interface Props {
  children: React.ReactElement;
}

const accessToken = localStorage.getItem("access_token");
const userCreds: User = JSON.parse(localStorage.getItem("user") as string) ?? {
  first_name: "",
  last_name: "",
  middle_name: "",
  is_superuser: false,
  groups: [],
  username: "",
};

export default function AuthProvider(props: Props): React.ReactElement {
  const { children } = props;

  const [user, setUser] = useState<UserWithAuth>({
    isAuth: Boolean(accessToken),
    first_name: userCreds.first_name,
    last_name: userCreds.last_name,
    middle_name: userCreds.middle_name,
    is_superuser: userCreds.is_superuser,
    groups: userCreds.groups,
    username: userCreds.username,
  });

  const value = useMemo(() => ({ user, setUser }), [user]);

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}
