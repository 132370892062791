/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import getOrFetch from "@/helpers/get-or-fetch";
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";
import { formsQuery } from "./queries";
import Details from "./views/details";
import Container from "./views/container";

const FolderIcon = lazy(() => import("./components/folder-icon"));
const Forms = lazy(() => import("./views"));

const formsRoutes = canSee(
  {
    id: "tablet-forms",
    title: "tablet-forms",
    path: "tablet-forms",
    element: <Container of={<Forms />} />,
    Icon: FolderIcon,
    children: [
      {
        id: "tablet-form-details",
        title: "tablet-form-details",
        path: ":id",
        element: <Details />,
      },
    ],
    // loader: async () => {
    //   const res = await getOrFetch(formsQuery);
    //   return res;
    // },
  },
  getUserClass().role,
);

export default formsRoutes;
