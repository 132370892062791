import { take } from "ramda";

export default function takeUpTo<T>(count: number, arr: T[] | undefined): T[] {
  if (Array.isArray(arr)) {
    return typeof arr?.length === "number" && arr.length > 2
      ? take(count, arr)
      : arr;
  }
  return [];
}
