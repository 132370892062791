import { CloseOutlined } from "@ant-design/icons";

interface Props {
  children: React.ReactNode;
  destroy: () => void;
}

export default function MessageContent(props: Props): React.ReactElement {
  const { children, destroy } = props;

  return (
    <div className="flex items-center gap-4">
      {children}
      <CloseOutlined
        onClick={destroy}
        style={{
          background: "white",
          color: "#8498b4",
          border: "1px solid #f5f5f5",
          borderRadius: "4px",
          padding: "6px",
        }}
      />
    </div>
  );
}
