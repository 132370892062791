import groups from "@/config/groups";
import { useAuthContext } from "@/contexts";
import type { UserClass } from "@/types";

export default function useUserClass(): UserClass {
  const { user } = useAuthContext();

  const group = user.groups.find(({ name }) => name in groups);

  const role = group?.name ?? "";

  return { role, group };
}
