import clsx from "clsx";

interface TabularDataProps {
  rows: Array<{ name: string; value: React.ReactNode }>;
}

export default function TabularData(
  props: TabularDataProps,
): React.ReactElement {
  const { rows } = props;

  return (
    <div className="my-4 border border-[#d7dde6] rounded-lg bg-[#fafbfc] leading-[56px]">
      {rows.map((row, i) => (
        <div
          key={row.name}
          className={clsx(
            "flex items-center",
            i % 2 === 0 ? "" : "bg-white",
            i + 1 === rows.length ? "" : "border-b border-b-[#d7dde6]",
          )}
        >
          <span className="flex-1 px-4 min-h-[56px]">{row.name}</span>
          <span className="flex-1 px-4 min-h-[56px] border-l border-l-[#d7dde6]">
            {row.value}
          </span>
        </div>
      ))}
    </div>
  );
}
