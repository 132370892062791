import { isEmpty } from "radash";
import groups from "@/config/groups";
import type { User, UserClass } from "@/types";

export default function getUserClass(): UserClass {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}") as User;

  const userClass: UserClass = {
    role: "",
    group: undefined,
  };

  if (!isEmpty(user)) {
    const userGroup = user.groups.find(({ name }) => name in groups);

    if (typeof userGroup !== "undefined") {
      userClass.role = userGroup.name;
      userClass.group = userGroup;
    }
  }

  return userClass;
}
