import type { CacheQuery } from "@/types";
import { getRequest } from "../api";

export function requestQuery(
  inspectorId: string,
): CacheQuery<Awaited<ReturnType<typeof getRequest>>> {
  return {
    queryKey: ["registration-request", inspectorId],
    queryFn: async () => {
      const res = await getRequest(inspectorId);
      return res;
    },
  };
}

export default null;
