import { Card, Layout, Tag } from "antd";
import DeveloperNotice from "@/components/developer-notice";
import brandLogo from "@/assets/enjin-coin-(enj).svg";

export default function ProfileDelete(): React.ReactElement {
  return (
    <Layout className="flex-col justify-between">
      <div className="flex flex-col justify-center items-center gap-4 p-6 max-w-5xl m-auto">
        <header className="flex flex-col items-center gap-2 mb-4">
          <img src={brandLogo} width={48} height={48} alt="brand logo" />
          <h1 className="font-semibold text-[28px]">E-Dalolatnoma</h1>
        </header>

        <Card className="w-full">
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-2xl">
              Инструкция по удалению профиля
            </h1>

            <div className="font-medium text-base flex flex-col gap-4">
              <p>
                В мобильном приложении UZENERGO есть функция удаления профиля
                пользователя. При удалении профиля безвозвратно удаляется
                следующая информация о пользователе.
              </p>

              <div>1. История правонарушений</div>
              <div>2. Адреса</div>
              <div>3. Личные данные</div>
            </div>
          </div>
        </Card>

        <PairCards
          title="ШАГ 1."
          descriptions={
            <p>
              Для удаления профиля войдите в раздел «Настройки» из раздела меню
            </p>
          }
          imgSrc="/account-2.png"
        />

        <PairCards
          title="ШАГ 2."
          descriptions={
            <p>В открывшемся меню, выберите пункт «Удалить профиль»</p>
          }
          imgSrc="/settings-2.png"
        />

        <PairCards
          title="ШАГ 3."
          descriptions={
            <>
              <p>
                После нажатия на пункт «Удалить профиль», на экране появится
                предупреждение.
              </p>
              <p>Нажмите кнопку «Удалить» для удаления профиля</p>
            </>
          }
          imgSrc="/settings.png"
        />

        <PairCards
          title="ШАГ 4."
          descriptions={
            <p>
              Приложения вернется на страницу авторизации. Профиль успешно
              удален.
            </p>
          }
          imgSrc="/login-via-oneid.png"
        />
      </div>

      <DeveloperNotice styles="inherit" />
      <br />
    </Layout>
  );
}

interface PairCardsProps {
  title: string;
  descriptions: React.ReactNode;
  imgSrc: string;
}

function PairCards(props: PairCardsProps): React.ReactElement {
  const { title, descriptions, imgSrc } = props;

  return (
    <div className="w-full flex items-stretch gap-4">
      <Card className="flex-1 flex flex-col justify-center">
        <div className="flex flex-col items-center gap-4">
          <Tag
            color="processing"
            bordered={false}
            className="font-bold text-sm p-2"
          >
            {title}
          </Tag>

          <div className="text-center font-medium text-lg">{descriptions}</div>
        </div>
      </Card>

      <Card className="flex-1 flex justify-center">
        <img
          src={imgSrc}
          alt="visual representation"
          className="rounded-lg h-[386px]"
        />
      </Card>
    </div>
  );
}
