import { useTranslation } from "react-i18next";
import classes from "./styles.module.scss";

export default function TestMode(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <div className={classes.m_scroll}>
      <div className={classes.m_scroll__title}>
        <p>{t("system-mode-test")}</p>
        <p>{t("system-mode-test")}</p>
      </div>
    </div>
  );
}
