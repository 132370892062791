import secondsToDate from "@/helpers/seconds-to-date";
import type { TFunction } from "i18next";
import displayDeadline from "./display-deadline";

const renderDeadline = (t: TFunction, val: number): React.ReactElement => {
  const seconds = Math.abs(val * 60 * 60);
  const date = secondsToDate(seconds);
  const hasPassed = val < 0;
  return (
    <span className="text-[#8498B4] bg-[#fafbfc] border border-[#e2e7ee] rounded-md py-1 px-2">
      {displayDeadline(t, date, hasPassed)}
    </span>
  );
};

export default renderDeadline;
