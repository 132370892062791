import access, { inspectorAccess } from "@/config/access";
import features from "@/config/features";
import type { CustomRoute } from "@/types";

export default function canSee(
  route: CustomRoute,
  as: string,
): CustomRoute | null {
  const user = JSON.parse(
    localStorage.getItem("user") ?? '{"organization": {"code": 0}}',
  );

  return route;

  const org = user.organization.code;

  if (route.id in features) {
    const accessHolders = access[route.id as keyof typeof features];

    if (accessHolders.includes(as)) {
      if (as === "inspector") {
        const holders = inspectorAccess[route.id as keyof typeof features];
        console.log(holders, route.id);

        if (!holders.includes(org)) {
          return null;
        }
      }

      return route;
    }
  }

  return null;
}
