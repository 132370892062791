import type { CacheQuery } from "@/types";
import { getStatsData } from "../api";
import type { StatsParams } from "../types";

export function statsQuery(
  params?: StatsParams,
): CacheQuery<Awaited<ReturnType<typeof getStatsData>>> {
  return {
    queryKey: ["stats", params],
    queryFn: async () => {
      const res = await getStatsData(params);
      return res;
    },
  };
}

export default null;
