/**
 * Binary search for a max value without knowing the exact value, only that it can be under or over
 * It dose not test every number but instead looks for 1,2,4,8,16,32,64,128,96,95 to figure out that
 * you thought about #96 from 0-infinity
 *
 * @example findFirstPositive(x => matchMedia(`(max-resolution: ${x}dpi)`).matches)
 * @author Jimmy Wärting
 * @see {@link https://stackoverflow.com/a/35941703/1008999}
 * @param {function} fn       The function to run the test on (should return truthy or falsy values)
 * @param {number}   start=1  Where to start looking from
 * @param {function} d        (private)
 * @returns {number}          Intenger
 */
function findFirstPositive(
  fn: (x: number) => boolean,
  start = 1,
  d = (e, g, c) =>
    g < e
      ? -1
      : 0 < fn((c = (e + g) >>> 1))
      ? c == e || 0 >= fn(c - 1)
        ? c
        : d(e, c - 1)
      : d(c + 1, g),
): number {
  for (; 0 >= fn(start); start <<= 1);
  return d(start >>> 1, start) | 0;
}

const dpi = findFirstPositive(
  (x: number) => matchMedia(`(max-resolution: ${x}dpi)`).matches,
);

export default function getDpi(): number {
  return dpi;
}
