export default function Link(props: {
  href: string | undefined;
  baseless?: boolean;
}): React.ReactElement {
  const { href, baseless } = props;

  if (href === "" || typeof href !== "string") {
    return <div />;
  }

  return (
    <a
      href={`${baseless ? href : import.meta.env.VITE_EK_MEDIA_URL}/${href}`}
      style={{
        color: "#3266D5",
        textDecoration: "underline",
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {href.split("/").at(-1)}
    </a>
  );
}
