/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import type { CustomRoute } from "@/types";
import LoginOneId from "./views/login-oneid";

const Auth = lazy(() => import("./views/auth"));
const Login = lazy(() => import("./views/login"));
const Register = lazy(() => import("./views/register"));

function authRoutesWith(routesGetter: () => CustomRoute[]): CustomRoute {
  const authRoutes: CustomRoute = {
    id: "auth",
    title: "Auth",
    path: "auth",
    element: <Auth getRoutes={routesGetter} />,
    children: [
      {
        id: "login",
        title: "Login",
        path: "login",
        element: <Login />,
      },
      {
        id: "register",
        title: "Register",
        path: "register",
        element: <Register />,
      },
      {
        id: "login-oneid",
        title: "login-oneid",
        path: "login-oneid",
        element: <LoginOneId />,
      },
    ],
  };

  return authRoutes;
}

export default authRoutesWith;
