import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { langContext } from "@/contexts/lang-context";
import settings from "@/config/settings";
import "@/utils/i18n";
import type { AppLang } from "@/types";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
  children: React.ReactElement;
}

const initialLang = (localStorage.getItem("i18nextLng") ??
  settings.defaultLanguage) as AppLang;

export default function LangProvider(props: Props): React.ReactElement {
  const { children } = props;
  const queryClient = useQueryClient()
  const { i18n } = useTranslation();

  const [lang, setLang] = useState<AppLang>(initialLang);

  const value = useMemo(
    () => ({
      lang,
      changeLang: (lng: AppLang) => {
        void i18n.changeLanguage(lng)
        setLang(lng);
        void queryClient.invalidateQueries()
      },
    }),
    [lang, i18n],
  );

  return <langContext.Provider value={value}>{children}</langContext.Provider>;
}
