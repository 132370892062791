import { useTranslation } from "react-i18next";
import { Button, Layout } from "antd";
import TestMode from "@/components/test-mode/test-mode";
import DeveloperNotice from "@/components/developer-notice";
import brandLogo from "@/assets/enjin-coin-(enj).svg";

export default function LoginOneId(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <TestMode />

      <Layout style={{ background: "#fafbfc" }}>
        <main className="flex flex-col justify-center items-center gap-5 h-screen w-[360px] m-auto">
          <header className="flex flex-col items-center gap-2">
            <img src={brandLogo} width={48} height={48} alt="brand logo" />
            <h1 className="font-semibold text-[28px]">E-Dalolatnoma</h1>
          </header>

          <div className="flex flex-col items-center gap-2 text-center">
            <h2 className="font-bold text-lg">{t("welcome")}</h2>
            <p className="text-[#8498B4] text-[15px]">{t("welcome-info")}</p>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            className="w-full bg-[#40916C] rounded-xl h-12 font-medium text-[15px]"
            // loading={}
          >
            {t("signin")}
          </Button>
        </main>

        <div className="absolute bottom-6 ml-[50%] -translate-x-1/2 text-[#8498B4]">
          {t("login-footer")}
          <DeveloperNotice styles="inherit" />
        </div>
      </Layout>
    </>
  );
}
