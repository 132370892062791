/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";

const NotificationIcon = lazy(() => import("./components/notification-icon"));
const Notification = lazy(() => import("./views/notification"));

const notificationRoutes = canSee(
  {
    id: "notification",
    title: "notification",
    path: "notification",
    element: <Notification />,
    Icon: NotificationIcon,
    children: [],
  },
  getUserClass().role,
);

export default null;
