// Side Effects
import "@/lib/to-capital-case";
import "@/lib/parse";

interface Props {
  children: React.ReactElement;
}

export default function SideEffectsProvider(props: Props): React.ReactElement {
  const { children } = props;

  return children;
}
