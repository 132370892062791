import request from "@/utils/axios";
import type { StatsData, StatsParams } from "../types";

export async function getStatsData(
  params: StatsParams = {},
): Promise<StatsData> {
  const res: StatsData = await request({
    url: "/statistic-count/",
    method: "GET",
    params,
  });
  return res;
}

export default null;
