const colors = {
  primary: "#40916c",
  bg_primary: "#d8f3dc",
  grey: "#62738C",
  bg_grey: "#fafbfc",
  placeholder: "#8498B4",
  border: "#f5f5f5",
};

const sizes = {};

export { colors, sizes };
