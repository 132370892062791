import type { valueof } from "@/types";
import groups from "./groups";
import type features from "./features";
import organizations from "./organizations";

const access: Record<keyof typeof features, Array<keyof typeof groups>> = {
  statistics: [groups.superuser, groups.admin, groups.inspector],
  "acts-list": [groups.superuser, groups.admin, groups.inspector],
  notification: [],
  inspectors: [groups.superuser, groups.admin],
  "registration-requests": [groups.superuser, groups.admin],
  offenses: [groups.superuser, groups.admin, groups.inspector],
  "tablet-forms": [groups.superuser, groups.admin, groups.inspector],
};

export default access;

const inspectorAccess: Record<
  keyof typeof features,
  Array<valueof<typeof organizations>>
> = {
  statistics: [
    organizations.uzenergoinspeksiya,
    organizations.hududgaztaminot,
    organizations.hududelektrtarmoqlari,
  ],
  "acts-list": [
    organizations.uzenergoinspeksiya,
    organizations.hududgaztaminot,
    organizations.hududelektrtarmoqlari,
  ],
  inspectors: [],
  offenses: [organizations.uzenergoinspeksiya],
  "tablet-forms": [organizations.uzenergoinspeksiya],
  notification: [],
  "registration-requests": [],
};

export { inspectorAccess };
