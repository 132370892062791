import type { CacheQuery } from "@/types";
import { getOffense, getOffenseStatuses } from "../api";

export function statusesQuery(): CacheQuery<
  Awaited<ReturnType<typeof getOffenseStatuses>>
> {
  return {
    queryKey: ["statuses"],
    queryFn: async () => {
      const res = await getOffenseStatuses();
      return res;
    },
  };
}

export function offenseQuery(
  id: number,
): CacheQuery<Awaited<ReturnType<typeof getOffense>>> {
  return {
    queryKey: ["offense", id],
    queryFn: async () => {
      const res = await getOffense(id);
      return res;
    },
  };
}
